import { Navbar, Nav } from 'react-bootstrap';
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuth, logout } from '../utils';
import ContactUs from './ContactUs';

const Menu = () => {
  const [logged] = useAuth();

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">
        <img
          alt=""
          src="/logo192.png"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />
        {' '}
        Direct Print
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {logged
            ? (
              <>
                <LinkContainer to="/profile">
                  {/*
                    Here and below added active={false} because of this issue:
                    https://github.com/react-bootstrap/react-router-bootstrap/issues/242
                  */}
                  <Nav.Link active={false}>Profile</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/downloads">
                  <Nav.Link active={false}>Downloads</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/devices">
                  <Nav.Link active={false}>Devices</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/history">
                  <Nav.Link active={false}>History</Nav.Link>
                </LinkContainer>
              </>
            )
            : <></>}
        </Nav>
        <Nav className="">
          <Nav.Link
            active={false}
            className="btn btn-warning pl-3 pr-3 mr-md-2 ml-md-2"
            href="https://ecosystem.ventor.tech/product/direct-print-pro-one-time-payment/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy Odoo Direct Print PRO
          </Nav.Link>
          <LinkContainer to="/plans">
            <Nav.Link active={false}>Plans</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/downloads">
            <Nav.Link active={false}>Downloads</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/service">
            <Nav.Link active={false}>Service</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/docs">
            <Nav.Link active={false}>Docs</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/faq">
            <Nav.Link active={false}>FAQ</Nav.Link>
          </LinkContainer>
          <ContactUs />

          <span className="navbar-separator" />

          {!logged
            ? (
              <>
                <LinkContainer to="/login" className="btn btn-outline-warning pl-3 pr-3 mb-2 mb-md-0 mr-md-2 ml-md-2">
                  <Nav.Link>Log in</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/register" className="btn btn-warning pl-3 pr-3 mr-md-2 ml-md-2">
                  <Nav.Link>Sign Up</Nav.Link>
                </LinkContainer>
              </>
            )
            : (
              <Nav.Link className="btn btn-outline-warning pl-3 pr-3 mr-2 ml-2" onClick={() => { logout(); localStorage.clear(); }}>Logout</Nav.Link>
            )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menu;
