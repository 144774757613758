import React from 'react';
import Applications from '../Account/Applications';

const Home = () => (
  <div className="container">
    <Applications />
  </div>
);

export default Home;
